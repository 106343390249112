




















































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import SelectCard from "@monorepo/uikit/src/components/common/Select/SelectCard.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { convertSaveCardObject } from "@monorepo/utils/src/variables/projectsData/BaseUserInfoModal/convertSaveCardObject";
import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { IUserListCardElement } from "@monorepo/utils/src/types/IUserListCardElement";
import { IRole } from "@monorepo/utils/src/types/IRole";
import { cloneDeep } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { validateEmail } from "@monorepo/utils/src/api/utils";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";

export default defineComponent({
  name: "UsersInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    SelectRolesCard,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    CheckboxElement,
    CardModalChapter,
    SelectCard,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data() {
    return {
      ModalType,
      key: uuid(),
      viewUniqKey: "",
      modalElements: [],
      openedPanels: [0, 1],
      fields,
      zIndexSelectCard: 201,
      techSection: [],
      systemSection: [],
      cardMode: CardMode,
      section: Sections.USERS_LIST,
      generalSection: [],
      isEditPassword: false,
      userDefaultData: {} as IUserListCardElement,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getUserCardElement: (value: string) => Promise<IUserListCardElement>;
          getUserAccesses: (payload: { userId: string }) => Promise<Record<string, string[]>>;
          formValues: Record<string, unknown>;
          isShowAccess: boolean;
          oiks: Record<string, unknown>[];
          accessValues: Record<string, Record<string, any>>;
          key: string;
          userDefaultData: IUserListCardElement;
          getResultSaveData: (data: Record<string, unknown>) => {
            [key: string]: unknown;
          };
          changedFormValues: Record<string, unknown>;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value: string
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          if (this.isShowAccess) {
            const accessValuesObj = await this.getUserAccesses({ userId: value });
            const oikObj = this.oiks.reduce((res: Record<string, Record<string, unknown>>, item: Record<string, unknown>) => {
              res[item.id as string] = item;
              return res;
            }, {} as Record<string, Record<string, unknown>>);
            const oiksResult = ((accessValuesObj?.oikIds as string[]) || []).map((oikId: string) => oikObj[oikId]).filter((item) => item) as Record<
              string,
              unknown
            >[];
            this.accessValues = {
              fundIds: ((accessValuesObj?.fundIds as string[]) || []).reduce((res, item) => {
                res[item] = true;
                return res;
              }, {} as Record<string, boolean>),
              archiveIds: ((accessValuesObj?.archiveIds as string[]) || []).reduce((res, item) => {
                res[item] = true;
                return res;
              }, {} as Record<string, boolean>),
              oikIds: oiksResult,
            };
          }
          this.getUserCardElement(value)
            .then((data) => {
              this.formValues = {
                ...data,
                enable2FA: data.enable2FA || false,
                status: !data.active ? "Заблокирован" : !data.deleted ? "Активный" : "Удален",
                createDate: data?.createDate ? moment(data?.createDate).format("YYYY-MM-DD") : "",
                updateDate: data?.updateDate ? moment(data?.updateDate).format("YYYY-MM-DD") : "",
                blockedUntil:
                  !data.active && !data?.blockedUntil ? "Бессрочно" : data?.blockedUntil ? moment(data?.blockedUntil).format("YYYY-MM-DD") : "",
              };
              this.userDefaultData = cloneDeep(data);
              this.key = uuid();
              this.changedFormValues = this.getResultSaveData(this.formValues);
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.changedFormValues = this.getResultSaveData({});
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    statusColor(): string {
      const status: string = this.formValues.status as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Удален":
          return "#D34039";
        case "Заблокирован":
          return "#D34039";
        default:
          return "";
      }
    },
    currentRoles(): unknown[] {
      return []; // define in store
    },
    modalFieldTitle(): (field: string) => IModalElementType {
      return (field: string) => {
        return this.modalElements.find((item: IModalElementType) => item.value === field) || ({} as IModalElementType);
      };
    },
    title(this: { formValues: { username: string }; type: ModalType }): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return this.formValues.username;
        case ModalType.NEW:
        default:
          return "Новый пользователь";
      }
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
  },
  methods: {
    async getCardElement(value: string) {
      //define in store
      console.log(value);
      return [] as unknown[];
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    deleteElement(params: Record<string, unknown>) {
      //define in store
      console.log(params);
      return true;
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteElementCb,
          title: `Удалить пользователя «${this.formValues.username}»?`,
        })
      );
    },
    async deleteElementCb(this: {
      userDefaultData: IUserListCardElement;
      type: ModalType;
      refreshScroll: () => void;
      saveCard: (item: Record<string, unknown>) => Promise<boolean>;
      closeModal: () => void;
      getResultSaveData: (data: Record<string, unknown>) => {
        [key: string]: unknown;
      };
      deleteElement: (item: { ids: string[] }) => Promise<boolean>;
    }) {
      let resultData = { ...this.userDefaultData, deleted: true };
      const isSaved = await this.saveCard(this.getResultSaveData(resultData));
      if (isSaved) {
        this.refreshScroll();
        this.closeModal();
      }
    },
    async restoreElementCb(this: {
      userDefaultData: IUserListCardElement;
      type: ModalType;
      refreshScroll: () => void;
      saveCard: (item: Record<string, unknown>) => Promise<boolean>;
      closeModal: () => void;
      getResultSaveData: (data: Record<string, unknown>) => {
        [key: string]: unknown;
      };
      deleteElement: (item: { ids: string[] }) => Promise<boolean>;
    }) {
      let resultData = { ...this.userDefaultData, deleted: false };
      const isSaved = await this.saveCard(this.getResultSaveData(resultData));
      if (isSaved) {
        this.refreshScroll();
        this.closeModal();
      }
    },
    saveCard(data: unknown) {
      return data;
    }, //define in store
    refreshScroll() {
      console.log("");
    }, //define in store
    getResultSaveData(data: Record<string, string | boolean>) {
      const resultData: { [key: string]: string | boolean | number } = {
        id: data.id || this.formValues.id,
        ...data,
      };
      return convertSaveCardObject(resultData);
    },
    async saveUserAccesses(
      this: {
        accessValues: Record<string, Record<string, any>>;
        setUserAccesses: (payload: { userId: string; fundIds: string[]; archiveIds: string[]; oikIds: string[] }) => Promise<void>;
      },
      id?: string
    ) {
      if (!id) {
        return;
      }
      await this.setUserAccesses({
        userId: id,
        fundIds: Object.entries(this.accessValues?.fundIds || {})
          .filter(([, value]) => value)
          .map(([key]) => key),
        archiveIds: Object.entries(this.accessValues?.archiveIds || {})
          .filter(([, value]) => value)
          .map(([key]) => key),
        oikIds: ((this.accessValues?.oikIds as any[]) || []).map((oik) => oik.id as string),
      });
    },
    async onSave(
      this: {
        formValues: { id: string; roles: IRole[]; active: boolean };
        isShowAccess: boolean;
        accessValues: Record<string, Record<string, any>>;
        type: ModalType;
        fields: typeof fields;
        saveCard: (item: Record<string, unknown>) => Promise<boolean | any>;
        refreshScroll: () => void;
        closeModal: () => void;
        getResultSaveData: (data: Record<string, unknown>) => {
          [key: string]: unknown;
        };
        changedFormValues: Record<string, unknown>;
        saveUserAccesses: (id?: string) => Promise<void>;
        setUserAccesses: (payload: { userId: string; fundIds: string[]; archiveIds: string[]; oikIds: string[] }) => Promise<void>;
      },
      data: Record<string, string | boolean> & { status?: string; email: string }
    ) {
      const isNew = this.type === ModalType.NEW;
      let resultData = {
        ...data,
        id: data.id || this.formValues.id,
        roles: this.formValues.roles,
        active: isNew ? true : this.formValues.active,
      };

      const email = resultData.email;

      if (email && !validateEmail(email)) {
        showNotification("Проверьте правильность заполнения Email");
        return;
      }

      const savedData = await this.saveCard(this.getResultSaveData(resultData));
      if (savedData) {
        if (this.isShowAccess) {
          await this.saveUserAccesses(this.formValues.id || savedData.data?.id);
        }
        this.changedFormValues = this.getResultSaveData(resultData);
        this.refreshScroll();
        this.closeModal();
      }
    },
    changeContracts(contracts: Record<string, string>[], values: Record<string, string>) {
      (
        this as unknown as {
          $formulate: {
            setValues: (key: string, object: Record<string, string>) => void;
          };
        }
      ).$formulate.setValues("oik-info-modal", Object.assign(values, { contracts }));
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({} as IUserListCardElement & { status: string });
    const changedFormValues = ref({});
    const accessValues = ref({ fundIds: {}, archiveIds: {}, oikIds: [] });
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "systemAccessesView", Sections.USERS_LIST, props.type, "changeOpenedUserId");
    const { addQueryOpenedId } = useUniqueLinkModal(root, "systemAccessesView", "openedUserId");

    return {
      formValues,
      changedFormValues,
      accessValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
