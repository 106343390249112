
























































































































































































































































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/usersList/viewTitle";
import BaseUserInfoModal from "@monorepo/uikit/src/components/BaseUserModal/BaseUserInfoModal.vue";
import { generalSection, systemSection, techSection } from "@monorepo/utils/src/variables/projectsData/systemAccesses/sections";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/systemAccesses/modalElements";
import { checkKeyPressToChangeOpenedId as checkKeyPress } from "@monorepo/utils/src/utils/changeOpenedId";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import moment from "moment";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import mask from "@monorepo/utils/src/directives/mask";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import AccessesTree from "@monorepo/uikit/src/components/BaseUserModal/AccessesTree.vue";

export default defineComponent({
  name: "UsersListInfoModal",
  components: {
    AccessesTree,
  },
  extends: BaseUserInfoModal,
  directives: {
    mask,
  },
  data() {
    return {
      viewUniqKey,
      section: Sections.USERS_LIST,
      openedPanels: [0, 1],
      techSection,
      systemSection,
      generalSection,
      modalElements,
      techElements,
      baseModalElements,
      isEditPassword: false,
      maskLogin: {
        regex: "^[0-9a-zA-Z-_][0-9a-zA-Z-_\\s]+$",
        placeholder: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("systemAccessesView", { openedId: "openedUserId" }),
    ...mapGetters("systemAccessesView", ["currentRoles", "defaultTwoStepsAuth", "certificates", "infiniteId", "oiks"]),
    title(this: { formValues: { username: string }; type: ModalType }): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return "Карточка пользователя";
        case ModalType.NEW:
        default:
          return "Новый пользователь";
      }
    },
    certificatesOptions(): { value: string; title: string }[] {
      return (this.certificates || []).map((item: { value: string; displayName: string }) => ({
        value: item.value.toString(),
        title: item.displayName || item.value,
      }));
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.USER_MODIFY);
    },
    isShowActivate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_ACTIVATE);
    },
    isShowDeactivate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_DEACTIVATE);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_CREATE);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.USER_DELETE);
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowAccess(): boolean {
      return isAuthorityExist(this.user, authorities.ADMIN);
    },
  },
  methods: {
    ...mapActions("app", ["sendChangePasswordEmail"]),
    ...mapActions("systemAccessesView", {
      changeOpenedId: "changeOpenedUserId",
    }),
    ...mapActions("systemAccessesView", [
      "getUserCardElement",
      "getVipnetCerts",
      "saveCard",
      "refreshScroll",
      "deleteElement",
      "resetPassword",
      "blockUser",
      "unblockUser",
      "getUserAccesses",
      "setUserAccesses",
      "getArchiveHierarchy",
    ]),
    async sendEmailPassword(this: {
      formValues: { [key: string]: string };
      fields: Record<string, string>;
      sendChangePasswordEmail: (email: string) => Promise<void>;
    }) {
      const email: string = this.formValues[this.fields.USER_EMAIL];
      if (!email) {
        showNotification("Отсутствует email пользователя");
      }
      await this.sendChangePasswordEmail(email);
    },
    async resetPasswordCb() {
      this.isEditPassword = true;
    },
    openBlockModal(event: { active: boolean; id: string }) {
      if (!event.active) {
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "UnblockUserModal", {
            cbOnUnblock: () => this.cbOnUnblock(event),
          })
        );
      } else {
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "BlockUserModal", {
            cbOnBlock: (config: { selectInfo: string; date: string }) => this.cbOnBlock(event, config),
          })
        );
      }
    },
    async cbOnBlock(event: { active: boolean; id: string }, config: { selectInfo: string; date: string }) {
      const isBlocked = await this.blockUser({
        id: event.id,
        blockUntil: config.selectInfo !== "all" ? moment(config.date, "YYYY-MM-DD").toISOString() : undefined,
      });
      if (isBlocked) {
        (this as unknown as { formValues: { active: boolean } }).formValues.active = false;
        (this as unknown as { formValues: { status: string } }).formValues.status = "Заблокирован";
        return event;
      }
    },
    async cbOnUnblock(event: { active: boolean; id: string }) {
      const isUnBlocked = await this.unblockUser(event.id);
      if (isUnBlocked) {
        (this as unknown as { formValues: { active: boolean } }).formValues.active = true;
        (this as unknown as { formValues: { status: string } }).formValues.status = "Активный";
      }
    },
    checkKeyPress,
    isShowActivateBtn(event: { active: boolean }): boolean {
      if (event.active) {
        return this.isShowActivate;
      } else {
        return this.isShowDeactivate;
      }
    },
  },
  created(this: { getVipnetCerts: () => void; formValues: { enable2FA: boolean }; type: ModalType; defaultTwoStepsAuth: boolean }) {
    if (this.type === ModalType.NEW) {
      this.formValues = { ...this.formValues, enable2FA: this.defaultTwoStepsAuth };
    }
    this.getVipnetCerts();
  },
  setup(props, { root }) {
    root.$store.dispatch("systemAccessesView/getCurrentRolesList");
  },
});
